@import "../../../vars_smart";

body {background-color: white; }

#login, .content { height: 100%; }

img { max-width: 100%; }

#login {
  //background: url("../../../images/grenoble.png") no-repeat center center fixed;
  background: url("../../../images/scott.jpg") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  
  h2 {
    margin-top: 10px;
    font-size: 1.8rem;
    color: #667077;
  }
  
  .informations-creation-compte,
  .informations-password {
    margin-top: 20px;
    
    .wrapper-login-client-existant {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  
  .informations-password {
    text-align: left !important;
  
    .validation-longueur,
    .validation-chiffre,
    .validation-lettre,
    .validation-majuscule,
    .validation-minuscule {
      display: none;
      color: red;
      
      &.validation-ok {
        color: green;
      }
    }
  }
  
  .feature-panel, .main-panel {
    align-self: center;
  }
  
  .feature-panel {
    //background: url("../../../images/grenoble.png") no-repeat center center scroll;
    background: url("../../../images/scott.jpg") no-repeat center center scroll;
    background-size: cover !important;
    height: 100%;
    position: relative;
    
    .mask {
      background-color: rgba(255, 255, 255, 0.1);
      height: 100%;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
    }
  }
  
  .main-panel {
    background: rgba(255,255,255,0.9);
    max-height: 100%;
    overflow-y: auto;
    
    &.transition {
      overflow: hidden;
    }
    
    img.logo {
      max-height: 140px;
      margin-bottom: 15px;
    }
    
    .forgotten-pwd-btn {
      background: #2c8c60;
      
      &:hover {
        background: #2c8c60;
      }
    }
  }
  
  .forms-container {
    position: relative;
    height: 375px;
    overflow: auto;
  
    .wrapper-form-password,
    .wrapper-form-connexion {
      width: 100%;
      padding: 0;
      transition-property: left;
      transition-duration: 0.5s;
      position: absolute;
      top: 0;
      
      ul {
        margin-bottom: 0;
        padding-left: 5px;
      }
      
      .input-group {
        .password-eye-icons {
          display: flex;
          position: absolute;
          top: 10px;
          right: 20px;
          z-index: 10;
          cursor: pointer;
          transition: all 0.2s;
          
          i {
            &:hover {
              color: $acte2i-color;
            }
            
            &.password-shown {
              display: none;
              position: relative;
              left: 1px;
            }
          }
        }
      }
    }
    
    .wrapper-form-connexion {
      left: 0;
  
      &.disabled {
        left: 150%;
      }
    }
  
    .wrapper-form-password {
      left: 0;
      
      &.disabled {
        left: -150%;
      }
    }
  }
}

.form-switch {
  cursor:pointer;
}

//Customise Bootstrap Input Styles

.input-group-text,
.input-group {
  background: #fff;
  height: 35px;
}

.input-group > .form-control:not(:first-child) {
  border-left-style:dashed;
}

.input-group > .input-group-prepend > .input-group-text {
  border-right:none;
}

.form-control{
  transition: 0.5s;
  
  &:focus {
    border-color: #bbb;
    border-style: dashed;
    box-shadow: 600px 0 0 0 inset #fafafa, 600px 0 0 0 inset #ddd;
  }
}

.input-group.is-invalid {
  & > .input-group-prepend > .input-group-text {
    border-color: #DC3545;
    color: #DC3545;
  }
  .form-control {
    border-color: #DC3545;
  }
  .invalid-feedback, .invalid-tooltip {
    display: block;
  }
}

.input-group.is-valid {
  & > .input-group-prepend > .input-group-text { color:#28A745; }
  
  .valid-feedback, .valid-tooltip {
    display: block;
  }
}

@media (min-width: 768px) {
  #login { background: none !important; }
}
